export default function Project(props) {
	return (
		<div className="project__card--container">
			{/* contains the card*/}
			{/* is the card */}
			<div className="project__card">
				{/* front of the card */}
				<div className="project__card--front">
					<img
						src={props.img}
						className="project__card--img"
						alt="Screenshot of the project"
					/>
					<h3 className="project__card--title">{props.title}</h3>
				</div>
				{/* back of the card */}

				<div className="project__card--back">
					<a href={props.liveSiteURL} className="project__card--title">
						<h3>{props.title}</h3>
					</a>

					<p className="project__card--tools">({props.tools})</p>
					<span className="project__card--viewCode">
						&lt;
						<a href={props.sourceCode} className="project__card--viewCode">
							View Code
						</a>
						&gt;
					</span>
					<p className="project__card--description">{props.desc}</p>
				</div>
			</div>
		</div>
	)
}
