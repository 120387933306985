import interactiveCard from "./images/screenshots/interactive-card-ss.png"
import cartUI from "./images/screenshots/cart-ui-ss.png"
import criminalRecordManagementSystem from "./images/screenshots/criminal_record_management_system_ss.png"
import gcEnterprises from "./images/screenshots/gc_enterprises_ss.png"
import pwGenerator from "./images/screenshots/pw-generator-ss.png"

const projectsData = [
	{
		id: 1,
		title: "Criminal Record Management System",
		description:
			"A criminal record management system to search, view, add, edit and delete records of people.",
		image: criminalRecordManagementSystem,
		tools: "React.js, Node.js, Express.js, MySQL, Tailwind CSS",
		liveSiteURL: "",
		sourceCode:
			"https://github.com/SandeshGC/Criminal-Record-Management-System",
	},
	{
		id: 2,
		title: "GC Enterprises",
		description: "Modern e-commerce website.",
		image: gcEnterprises,
		tools: "Next.js",
		liveSiteURL: "https://gc-enterprises.vercel.app/",
		sourceCode: "https://github.com/SandeshGC/gc-enterprises",
	},

	{
		id: 3,
		title: "Interactive Card Details Form",
		description: "Enter your details and see the card update in real time.",
		image: interactiveCard,
		tools: "HTML, CSS, React JS",
		liveSiteURL: "https://sandeshgc.github.io/Interactive-card-details-form/",
		sourceCode: "https://github.com/SandeshGC/Interactive-card-details-form",
	},

	{
		id: 4,
		title: "Ecommerce Cart UI",
		description: "Simple sample cart UI for an ecommerce website",
		image: cartUI,
		tools: "HTML, CSS, JavaScript",
		liveSiteURL: "https://sandeshgc.github.io/cart-ui-challenge/",
		sourceCode: "https://github.com/SandeshGC/cart-ui-challenge",
	},

	{
		id: 5,
		title: "Password Generator",
		description:
			"Generates a random password using alphabets, numbers and valid special characters.",
		image: pwGenerator,
		tools: "HTML, CSS, JavaScript",
		liveSiteURL: "https://sandeshgc.github.io/password-generator/",
		sourceCode: "https://github.com/SandeshGC/password-generator",
	},
]

export default projectsData
