import "../styles/header.css"
import React from "react"
import { NavLink } from "react-router-dom"
export default function Header() {
	const styles = ({ isActive }) => {
		return isActive
			? {
					color: "var(--lime)",
					textDecoration: "underline",
					textDecorationStyle: "wavy",
			  }
			: { color: "var(--lime-medium)" }
	}
	return (
		<header className="header">
			{/* <h1 className="site--title text--silkscreen">Sandesh GC</h1> */}
			<nav className="nav">
				<ul className="nav--tabs">
					<li className="nav--item">
						<NavLink to="/" style={styles} className="text--silkscreen">
							About
						</NavLink>
					</li>
					<li className="nav--item">
						<NavLink
							to="/portfolio"
							style={styles}
							className="text--silkscreen"
						>
							Portfolio
						</NavLink>
					</li>
					<li className="nav--item">
						<NavLink to="/contact" style={styles} className="text--silkscreen">
							Contact
						</NavLink>
					</li>
					<li className="nav--item">
						<NavLink to="/resume" style={styles} className="text--silkscreen">
							Resume
						</NavLink>
					</li>
				</ul>
			</nav>
		</header>
	)
}
