import resumePDF from "../files/Sandesh_GC_CV.pdf"
export default function Resume() {
	return (
		<section>
			<a href={resumePDF} download={true}>
				&gt; Download Resume
			</a>
		</section>
	)
}
