import "../styles/about.css"
import myImage from "../images/sandesh_gc.jpg"
export default function About() {
	return (
		<section className="about--section container">
			<img
				src={myImage}
				className="my--img"
				alt="Man with glasses in black shirt with hills in the background."
			/>
			<p className="about--title text--silkscreen">Sandesh G.C.</p>

			<div className="about--text">
				<p style={{ fontWeight: 800 }}>&#64; PRESENT...</p>

				{/* SKILLS */}
				<div>
					<p className="about--text__indented2">&#35; SKILLS:-</p>
					<p className="about--text__indented4">&raquo; HTML &amp; CSS</p>
					<p className="about--text__indented4">&raquo; JavaScript</p>
					<p className="about--text__indented4">&raquo; React.js</p>
					<p className="about--text__indented4">&raquo; Git &amp; GitHub</p>
					<p className="about--text__indented4">&raquo; Express.js</p>
					<p className="about--text__indented4">&raquo; MySQL</p>
					<p className="about--text__indented4">&raquo; Next.js</p>
					<p className="about--text__indented4">&raquo; Tailwind CSS</p>
				</div>

				{/* STUDYING */}
				<div>
					<p className="about--text__indented2">&#35; STUDYING:-</p>
					<p className="about--text__indented4">
						&raquo; Bachelor of Computer Engineering
             (2019 - 2024)
					</p>
					<p className="about--text__indented4">
						&raquo; T.U. I.O.E. Pashchimanchal Campus, Pokhara
					</p>
				</div>

				{/* LEARNING */}
				<div>
					<p className="about--text__indented2">&#35; LEARNING:-</p>
					<ul>
						<li className="about--text__indented4">&raquo; Node.js</li>
						<li className="about--text__indented4">&raquo; Express.js</li>
						<li className="about--text__indented4">&raquo; MongoDB</li>
					</ul>
				</div>
			</div>
		</section>
	)
}
