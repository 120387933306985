import "../styles/portfolio.css"
import Project from "./Project"
import projectsData from "../projectsData"
export default function Portfolio() {
	// maps the projectsData array and returns Project component for each data
	const listOfProjects = projectsData.map((eachProject) => {
		const { id, title, description, image, tools, liveSiteURL, sourceCode } =
			eachProject
		return (
			<Project
				key={id}
				title={title}
				desc={description}
				img={image}
				tools={tools}
				liveSiteURL={liveSiteURL}
				sourceCode={sourceCode}
			/>
		)
	})
	return <section className="portfolio">{listOfProjects}</section>
}
