import "../styles/footer.css"
import "font-awesome/css/font-awesome.min.css"

export default function Footer() {
	return (
		<footer className="footer">
			<div className="social__media--icons">
				<a
					href="https://www.facebook.com/gcsandesh01"
					target="_blank"
					rel="noreferrer"
				>
					<i className="fa-brands fa-square-facebook fa-xl" />
				</a>
				<a
					href="https://www.instagram.com/gcsandesh_01"
					target="_blank"
					rel="noreferrer"
				>
					<i className="fa-brands fa-square-instagram fa-xl" />
				</a>
				<a
					href="https://www.github.com/SandeshGC"
					target="_blank"
					rel="noreferrer"
				>
					<i className="fa-brands fa-square-github fa-xl" />
				</a>
				<a
					href="https://www.linkedin.com/in/sandesh-gc-8236b2195"
					target="_blank"
					rel="noreferrer"
				>
					<i className="fa-brands fa-linkedin fa-xl" />
				</a>
			</div>
			<div className="below--footer text--silkscreen">
				&copy;SandeshGC&emsp;2023&emsp;All rights reserved.
			</div>
		</footer>
	)
}
