import "../styles/contact.css"
export default function Contact() {
	return (
		<section>
			Like my works? Get in touch!
			<div className="contact">
				<h2 className="text--silkscreen">Sandesh GC</h2>
				<div className="contact__details">
					<p className="contact__details--row contact__details--email">
						<span className="fa--icon">
							<i className="fa-solid fa-envelope"></i>
						</span>
						gcsandesh01@gmail.com
					</p>
					<p className="contact__details--row contact__details--location">
						<span className="fa--icon">
							<i className="fa-solid fa-location-dot"></i>
						</span>
						Pokhara- 16, Kaski
					</p>
				</div>
			</div>
		</section>
	)
}
